var Measurement = function () {
    var pub = {};
    var frictionImages = [];
    var contaminantImage;

    const CONTAMINANT_MODE = "ContaminantMode";
    const FRICTION_MODE = "FrictionMode";

    var graphMode = FRICTION_MODE;

    var savedData;

    var defaults = {
        rest: {
            url: {
                base: '',
                measurement: '/measurement',
                drive: '/drive',
                dataBlock: '/datablock',
                measurementReport: '/measurementReport',
                measurementType: '/measurementReportType',
                contaminantImage: '/contaminantImage',
                measurementCsv: '/measurementCsv',
                contaminantCsv: '/contaminantCsv',
                measurementSnowtam: '/measurementSnowtam',
                measurementSnowtamReport: '/snowtamReport',
                measurementPdf: '/measurementPdf'
            }
        },
        dom: {
            screen: 'measurement-screen',
            data: 'measurement-data',
            refresh: 'refresh',
            map: 'measurement-map',
            report: 'measurement-report',
            startedDate: 'measurement-started-date',
            startedTime: 'measurement-started-time',
            finishedDate: 'measurement-finished-date',
            finishedTime: 'measurement-finished-time',
            showFriction: 'measurement-show-friction',
            showContaminant: 'measurement-show-contaminant',
            print: 'measurement-button-print',
            downloadCsv: 'measurement-button-download-csv',
            download: 'measurement-combined-download-button',
            
            remove: 'measurement-button-remove',
            label: 'measurement-span-header',
            timestamp: 'measurement-header-timestamp',
            snowtam: 'snowtam-data',
            depositGrid: 'deposit-data'
        }
    }

    $('#measurement-show-contaminant').on('click', onShowContaminantButtonClicked);
    $('#measurement-show-friction').on('click', onShowFrictionButtonClicked);

    var options = {};

    var elements = {
        screen: false,
        data: false,
        refresh: false,
        map: false,
        report: false,
        time: false,
        downloadCsv: false,
        remove: false,
        print: false,
        showContaminant: false,
        showFriction: false,
        label: false,
    };

    var currentUuid;
    var currentSnowtamUuid;

    var map;

    var polylines = [];

    pub.init = function (overrides) {
        options = $.extend(true, {}, defaults, overrides);

        initElements();
        initMap();
    };

    pub.show = function (uuid) {
        frictionImages = [];
        contaminantImage = "";
        elements.showFriction.hide()
        screenOn();
        load(uuid);
    };

    pub.downloadContaminantCsv = function () {
        if (currentUuid) {
            var url = ContaminantCsvUrl(currentUuid);
            $.get(url)
                .done(function (data) {
                    var blob = new Blob([data]);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "contaminantData.csv";
                    link.click();
                })
                .fail(function () {
                    alert('No contaminant .CSV Found');
                });
        }
    };

    pub.downloadCsv = function (resolution, includeGpsPosition) {
        console.log("DOWNLOADING CSV")
        if (currentUuid) {
            window.location = MeasurementCsvUrl(currentUuid, resolution, includeGpsPosition);
        }
    };

    pub.downloadPdf = function () {
        if (currentUuid) {
            window.location = MeasurementPdfUrl(currentUuid);
        }
    };

    pub.isContaminantMeasurement = function () {
        if (typeof savedData.contaminantPolylines !== 'undefined') {
            if (savedData.contaminantPolylines[0].data.length > 0) {
                return true;
            }
        }
        return false;
    }

    pub.print = function () {
        elements.report.print({
            timeout: 2000
        });
    }

    pub.downloadSnowtamPdf = function () {
        if (currentSnowtamUuid) {
            var url = measurementSnowtamReportUrl(currentSnowtamUuid);

            $.get(url)
                .done(function (data) {
                    var blob = new Blob([data]);
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = currentSnowtamUuid + '.pdf';
                    link.click();
                })
                .fail(function () {
                    alert('No Snowtam PDF found');
                });
        }
    }

    function initElements() {
        elements.screen = $('#' + options.dom.screen);
        elements.data = $('#' + options.dom.data);
        elements.map = $('#' + options.dom.map);
        elements.report = $('#' + options.dom.report);
        elements.startedDate = $('#' + options.dom.startedDate);
        elements.startedTime = $('#' + options.dom.startedTime);
        elements.finishedDate = $('#' + options.dom.finishedDate);
        elements.finishedTime = $('#' + options.dom.finishedTime);
        elements.downloadCsv = $('#' + options.dom.downloadCsv);
        elements.download = $('#' + options.dom.download);

        elements.remove = $('#' + options.dom.remove);
        elements.print = $('#' + options.dom.print);
        elements.label = $('#' + options.dom.label);
        elements.timestamp = $('#' + options.dom.timestamp);
        elements.snowtam = $('#' + options.dom.snowtam);
        elements.depositGrid = $('#' + options.dom.depositGrid);
        elements.showContaminant = $('#' + options.dom.showContaminant);
        elements.showFriction = $('#' + options.dom.showFriction);


        hideActionButtons();
        hideLabel();
        hideMap();
    }

    function initMap() {
        map = new google.maps.Map(document.getElementById(options.dom.map), {
            center: { lat: -34.397, lng: 150.644 },
            zoom: 8
        });
    }

    function load(uuid) {
        currentUuid = false;
        currentSnowtamUuid = false;
        frictionImages = [];
        contaminantImage = false;
        showLoading();

        var url = MeasurementUrl(uuid);

		
        $.get(url)
            .done(function (data) {
                currentUuid = uuid;

                bind(data.data);
                screenOff();

                showActionButtons();
                showLabel();
                showMap();
                savedData = data.data;
                if (typeof savedData.contaminantPolylines !== 'undefined') {
                    if (savedData.contaminantPolylines[0].data.length > 0) {
                        elements.showContaminant.show();
                        //hasContaminantReport = true;
                    }
                } else {
                    elements.showContaminant.hide();

                }
            })
            .fail(function () {
                panic();
            });
    }

    pub.remove = function () {
        if (currentUuid) {
            var options = {
                url: MeasurementUrl(currentUuid),
                type: 'DELETE'
            };

            $.ajax(options)
                .done(function (data) {
                    if (data.status === 'success') {
                        $.notify({
                            icon: 'glyphicon glyphicon-remove-circle',
                            message: 'Removed',
                        }, {
                            placement: {
                                from: 'bottom',
                                align: 'right'
                            },
                            animate: {
                                enter: 'animated fadeInUp',
                                exit: 'animated fadeOutDown'
                            }
                        });
                    }
                })
                .fail(function () {
                    $.notify({
                        icon: 'glyphicon glyphicon-remove-circle',
                        message: 'Failed!'
                    });
                });
        }
    }

    function bind(data) {
        bindStarted(data);
        bindFinished(data);
        bindReport(data);
        bindLabel(data);
        bindMap(data);

        var snowtamEnabled = parseInt(stringify(data.snowtamEnabled));

        if (snowtamEnabled) {
            currentSnowtamUuid = data.snowtam.uuid;
            bindSnowtam(data.snowtam);
            elements.snowtam.show();
            elements.depositGrid.show();
        } else {
            elements.snowtam.hide();
            elements.depositGrid.hide();
        }


        /*
        if (hasFrictionReport) { //empty if contaminant report
            console.log("friction or combined")
            //onShowContaminantButtonClicked();
            //elements.showContaminant.hide();
        }
        else {
            console.log("contaminant")
            //onShowFrictionButtonClicked();
            //console.log(contaminantImage);
        }
        */

    }

    function bindMap(data) {
        clearPolylines(polylines);

        if (data.hasOwnProperty('routePolylines')) {
            polylines = convertPolylines(data.routePolylines[0].data, map);
        } else {
            map.setZoom(1);
        }
    }

    function bindReport(data) {
        elements.report.empty();
        hasFrictionReport = false;
        hasContaminantReport = false;

        checkReportType(data.uuid);
        fetchSingleFrictionImage(data.uuid);
        fetchContaminantImage(data.uuid);
    }

    function bindLabel(data) {
        var surface = (data.surface && data.surface.name) ? data.surface.name : "";
        var date = moment.unix(data.started).format('YYYY-MM-DD') || "";
        var time = moment.unix(data.started).format('HH:mm:ss') || "";
        elements.label.text(surface);
        elements.timestamp.text("(" + date + " " + time + ")");
    }

    function bindStarted(data) {
        var date = moment.unix(data.started).format('YYYY-MM-DD') || "";
        var time = moment.unix(data.started).format('HH:mm:ss') || "";

        elements.startedDate.text(date);
        elements.startedTime.text(time);
    }

    function bindFinished(data) {
        var date = moment.unix(data.finished).format('YYYY-MM-DD');
        var time = moment.unix(data.finished).format('HH:mm:ss');

        elements.finishedDate.text(date);
        elements.finishedTime.text(time);
    }

    function stringify(value) {
        return (value == undefined) ? '' : value;
    }

    function bindSnowtam(data) {
        var criticalSnowbanksDistance = stringify(data.criticalSnowbanksDistance)
        $('#snowtam-critical-snowbanks-distance').text(criticalSnowbanksDistance);
        var criticalSnowbanksHeight = stringify(data.criticalSnowbanksHeight)
        $('#snowtam-critical-snowbanks-height').text(criticalSnowbanksHeight);

        var criticalSnowbanksPresent = stringify(data.criticalSnowbanksPresent)
        criticalSnowbanksPresent = (criticalSnowbanksPresent == 1) ? 'YES' : 'NO';
        $('#snowtam-critical-snowbanks-present').text(criticalSnowbanksPresent);

        var furtherClearance = parseInt(stringify(data.furtherClearance));
        switch (furtherClearance) {
            case 0: furtherClearance = 'NOT NEEDED'; break;
            case 1: furtherClearance = 'ENTIRE RUNWAY'; break;
            case 2: furtherClearance = 'CUSTOM'; break;
            default: furtherClearance = '';
        }
        $('#snowtam-further-clearance').text(furtherClearance);

        $('#snowtam-further-clearance-completed-by').text(moment(data.furtherClearanceCompletedBy, "X").format("HHmm"));

        var furtherClearanceLength = stringify(data.furtherClearanceLength)
        $('#snowtam-further-clearance-length').text(furtherClearanceLength);

        var furtherClearanceWidth = stringify(data.furtherClearanceWidth)
        $('#snowtam-further-clearance-width').text(furtherClearanceWidth);

        var nextPlannedMeasurement = stringify(data.nextPlannedMeasurement)
        $('#snowtam-next-planned-measurement').text(moment(data.nextPlannedMeasurement, "X").format("MMDDHHmm"));

        var runwayLights = parseInt(stringify(data.runwayLights));
        switch (runwayLights) {
            case 0: runwayLights = 'NOT OBSCURED'; break;
            case 1: runwayLights = 'LEFT'; break;
            case 2: runwayLights = 'RIGHT'; break;
            case 3: runwayLights = 'LEFT AND RIGHT'; break;
            default: runwayLights = '';
        }
        $('#snowtam-runway-lights').text(runwayLights);

        var taxiwaySnowbanksPresent = parseInt(stringify(data.taxiwaySnowbanksPresent));
        taxiwaySnowbanksPresent = (taxiwaySnowbanksPresent == 1) ? 'YES' : 'NO';
        $('#snowtam-taxiway-snowbanks-present').text(taxiwaySnowbanksPresent);

        var taxiwaySnowbanksHeight = stringify(data.taxiwaySnowbanksHeight);
        $('#snowtam-taxiway-snowbanks-height').text(taxiwaySnowbanksHeight);

        var operationallySignificantInformation = stringify(data.operationallySignificantInformation)
        $('#snowtam-operationally-significant-information').text(operationallySignificantInformation);

        //Deposit grid

        var activeDeposits = data.deposits;

        for (var i = 0; i < activeDeposits.length; i++) {
            var deposit = activeDeposits[i];
            var depositLocation = parseInt(deposit.location) * 9 + parseInt(deposit.type);

            var depositColumn = $('#col-' + depositLocation);

            depositColumn.addClass('deposit-active');
            depositColumn.html(deposit.percentage + ' % <br>' + deposit.depth + " mm");
        }
    }

    function convertPolylines(polylines, map) {
        var convertedPolylines = [];

        var bounds = new google.maps.LatLngBounds();

        for (var i = 0; i < polylines.length; i++) {
            var polyline = polylines[i];

            var convertedPolyline = new google.maps.Polyline({
                path: convertPoints(polyline.p, bounds),
                strokeColor: polyline.c,
                strokeOpacity: 1.0,
                strokeWeight: 5
            });

            convertedPolyline.setMap(map);
            convertedPolylines.push(convertedPolyline);
        }

        google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
            this.setZoom(14);
        });

        map.fitBounds(bounds);

        return convertedPolylines;
    }
    function convertPoints(points, extendBounds) {
        var convertedPoints = [];

        for (i = 0; i < points.length; i++) {
            var point = points[i];

            var convertedPoint = new google.maps.LatLng(point[0], point[1]);

            if (extendBounds) {
                extendBounds.extend(convertedPoint);
            }

            convertedPoints.push(convertedPoint);
        }

        return convertedPoints;
    }


    function clearPolylines() {
        for (var i = 0; i < polylines.length; i++) {

            polylines[i].setMap(null);
        }
        polylines = [];
    }

    function panic() {
        showError(Lang.get('errors.failedToLoadData'));
    }

    function screenOn() {
        elements.data.hide();
        elements.screen.show();
    }

    function screenOff() {
        elements.screen.hide();
        elements.data.show();
    }

    function MeasurementUrl(uuid) {
        return options.rest.url.base + options.rest.url.measurement + '/' + uuid;
    }

    function DriveUrl(uuid) {
        return options.rest.url.base + options.rest.url.drive + '/' + uuid;
    }

    function DataBlockUrl(uuid) {
        return options.rest.url.base + options.rest.url.dataBlock + '/' + uuid;
    }

    function MeasurementReportUrl(uuid, position) {
        return options.rest.url.base + options.rest.url.measurementReport + '/' + uuid + '/pos/' + position;
    }
    function SingleMeasurementReportUrl(uuid) {
        return options.rest.url.base + options.rest.url.measurementReport + '/' + uuid;
    }
    function MeasurementTypeUrl(uuid) {
        return options.rest.url.base + options.rest.url.measurementType + '/' + uuid;
    }

    
    function ContaminantImageUrl(uuid) {
        return options.rest.url.base + options.rest.url.contaminantImage + '/' + uuid;
    }

    function MeasurementCsvUrl(uuid, resolution, includeGpsPosition) {
        resolution = parseInt(resolution);
        resolution = (resolution > 0) ? resolution : 10;

        includeGpsPosition = (includeGpsPosition) ? 'true' : 'false';

        return options.rest.url.base + options.rest.url.measurementCsv + '/' + uuid + '?resolution=' + resolution
                                                     + '&positionInterpolation=' + includeGpsPosition;
    }


    function MeasurementPdfUrl(uuid) {

        return options.rest.url.base + options.rest.url.measurementPdf + '/' + uuid;
    }

    
    function ContaminantCsvUrl(uuid) {
        return options.rest.url.base + options.rest.url.contaminantCsv + '/' + uuid;
    }

    function measurementSnowtamReportUrl(uuid) {
        return options.rest.url.base + options.rest.url.measurementSnowtamReport + '/' + uuid;
    }

    function MeasurementSnowtamUrl(uuid) {
        return options.rest.url.base + options.rest.url.measurementSnowtam + '/' + uuid;
    }

    function onShowContaminantButtonClicked() {
        graphMode = CONTAMINANT_MODE;
        elements.showContaminant.hide();
        elements.showFriction.show();
        showContaminantGraphImage();
        clearPolylines();
        changeMap(savedData.contaminantPolylines[0].data);
    }

    function onShowFrictionButtonClicked() {
        graphMode = FRICTION_MODE;
        elements.showFriction.hide();
        elements.showContaminant.show();
        clearPolylines();
        showFrictionGraphImages();
        if (savedData.hasOwnProperty('routePolylines') && savedData.routePolylines.length > 0 && savedData.routePolylines[0].hasOwnProperty('data')) {
            changeMap(savedData.routePolylines[0].data);
        }
    }

    function changeMap(polylineData) {
        var convertedPolylines = [];
        var bounds = new google.maps.LatLngBounds();

        for (var i = 0; i < polylineData.length; i++) {
            var polyline = polylineData[i];

            var convertedPolyline = new google.maps.Polyline({
                path: convertPoints(polyline.p, bounds),
                strokeColor: polyline.c,
                strokeOpacity: 1.0,
                strokeWeight: 5
            });

            convertedPolyline.setMap(map);
            convertedPolylines.push(convertedPolyline);
        }
        polylines = convertedPolylines.concat();
    }

    function showActionButtons() {
        elements.downloadCsv.show();
        elements.download.show();

        elements.remove.show();
        elements.print.show();
    }

    function showLabel() {
        elements.label.show();
        elements.timestamp.show();
    }

    function showMap() {
        if (elements.map.is(":hidden")) {
            elements.map.show();
        }
        refreshMapContents();
    }

    function refreshMapContents() {
        var center = map.getCenter();
        google.maps.event.trigger(map, 'resize');
        map.setCenter(center);
    }

    function hideActionButtons() {
        elements.downloadCsv.hide();
        elements.download.hide();

        elements.remove.hide();
        elements.print.hide();
        elements.showFriction.hide();
        elements.showContaminant.hide();

    }

    function hideLabel() {
        elements.label.hide();
        elements.timestamp.hide();
    }

    function hideMap() {
        elements.map.hide();
    }

    function showLoading() {
        elements.screen.text('');

        elements.downloadCsv.hide();
    }

    function showError(error) {
        elements.screen.text(error);
    }

    function checkReportType(uuid) {
        var url = MeasurementTypeUrl(uuid);
        validateUrl(url, uuid, function (valid) {
            var options = {
                url: url,
                type: 'GET'
            };

            $.ajax(options)
            .done(function (data) {
                if (data.contaminant) {
                    hasContaminantReport = true;
                }
                if (data.friction) {
                    hasFrictionReport = true;
                }
                showCorrectReportContents();
            })
            .fail(function () {
                
                showCorrectReportContents();

            });
        });
    }

    function fetchSingleFrictionImage(uuid) {
        var url = SingleMeasurementReportUrl(uuid);
        validateUrl(url, uuid, function (valid) {
            if (currentUuid == uuid) {

                if (valid) {
                    var img = $('<img />').attr('src', url);

                    frictionImages.push(img);
                    //hasFrictionReport = true;
                    showFrictionGraphImages();
                } else {
                    fetchFrictionImages(uuid, 0);
                }
            } else {
                frictionImages = [];
            }
        });
    }


    function fetchFrictionImages(uuid, position) {

        var url = MeasurementReportUrl(uuid, position);
        validateUrl(url, uuid, function (valid) {
            if (currentUuid == uuid) {
                if (valid) {
                    var img = $('<img />').attr('src', url);
                    frictionImages.push(img);

                    position++;
                    //hasFrictionReport = true;

                    fetchFrictionImages(uuid, position);
                } else {
                    showFrictionGraphImages();
                }
            } else {
                frictionImages = [];
            }
        });
    }

    function validateUrl(url, uuid, callback) {
        if (uuid != currentUuid) {

            callback(false);
            return;
        }

        $.get(url)
            .done(function (data) {
                callback(true);
            })
            .fail(function () {
                callback(false);
            });
    }

    function showFrictionGraphImages() {
        if (graphMode === CONTAMINANT_MODE) {
            return;
        }
        elements.report.empty();

        frictionImages.forEach(function (image) {
            elements.report.append(image);
        });
    }

    function fetchContaminantImage(uuid) {
        var url = ContaminantImageUrl(uuid);
        validateUrl(url, uuid, function (valid) {
            if (uuid == currentUuid) {
                if (valid) {
                    var img = $('<img />').attr('src', url);
                    contaminantImage = img;
                }
            } else {
                contaminantImage = "";
            }

        });
    }

    function showContaminantGraphImage() {
        if (graphMode === FRICTION_MODE) {
            return;
        }
        elements.report.empty();
        elements.report.append(contaminantImage);
    }

    function showCorrectReportContents() {

        
        if (!hasContaminantReport && hasFrictionReport) {
            onShowFrictionButtonClicked();
            elements.showContaminant.hide();
        }
        else if (hasFrictionReport && hasContaminantReport) {
            onShowFrictionButtonClicked();
        }
        else if (hasContaminantReport && !hasFrictionReport) {
            onShowContaminantButtonClicked();
            elements.showFriction.hide();
        }
        else {
            onShowFrictionButtonClicked();
        }

    }

    return pub;
};
